import React from "react"
import Wave from "../components/wave"
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from "gatsby"
import Image from "../components/image"


const Intro = () => (
		<div className="home_wrap">
			<Container fixed>
					<Grid container spacing={3}>
						<Grid item md={6} sm={12} data-sal="slide-right" data-sal-duration="2000" className="home_intro">
							<aside>
                                <h1>Welcome!</h1>
								<h2>Web, Tech, E-Commerce Solutions</h2>
                                <p>My web design and development services are tailored to create exceptional websites and e-commerce experiences that captivate and convert visitors into loyal customers.</p>
								<p>I specialize in designing visually stunning, user-friendly websites that not only reflect your brand’s identity but also drive sales and enhance customer satisfaction. From intuitive navigation and seamless checkout processes to responsive design and robust security features, I ensure that every aspect of your online store is optimized for performance and engagement.</p>
								{/* <p>By leveraging the latest technologies and best practices, we build e-commerce platforms that are both aesthetically pleasing and functionally efficient, helping your business thrive in the competitive digital landscape.</p> */}
                                <Button variant="contained" color="primary" className="hvr-push" style={{backgroundColor: "#448ccb", marginRight: "20px"}}><Link to="/about" className="btn_link">Learn More</Link></Button>
								<Button variant="contained" color="primary" className="hvr-push" style={{backgroundColor: "#448ccb"}}><Link to="/projects" className="btn_link">Projects</Link></Button>
							</aside>
						</Grid>
					</Grid>
			</Container>
			<Wave />
			<div className="home_details">
				<Container fixed>
					<Grid container spacing={3}>
						<Grid item md={4} sm={12} data-sal="flip-down" data-sal-duration="1500"  className="card_ani">
							<Card>
								<CardContent>
									<aside>
										<Image filename="ecommerce_icon.png" className="logos" />
										<h2>E-Commerce</h2>
										<p>I provide tailored e-commerce solutions designed to streamline your online business, enhance customer experience, and boost sales growth. From setting up multiple e-commerce platforms from WooCommerce, Amazon, and Etsy.</p>	
									</aside>
								</CardContent>
							</Card>
						</Grid>
						<Grid item md={4} sm={12} data-sal="flip-down" data-sal-duration="1500"  className="card_ani">
							<Card>
								<CardContent>
									<aside>
										<Image filename="design_icon.png" className="logos" />
										<h2>Design</h2>
										<p>With 25+ years experience designing websites, landing pages, marketing and advertisement campaigns, display banners, mock-ups and even print materials I have in-depth knowledge and skills to design on any platform.</p>
										</aside>
								</CardContent>
							</Card>
						</Grid>
						<Grid item md={4} sm={12} data-sal="flip-down" data-sal-duration="1500"  className="card_ani">
							<Card>
								<CardContent>
									<aside>
										<Image filename="development_icon.png" className="logos" />
										<h2>Development</h2>
										<p>I offer expert web development services to help bring your online vision to life with customized, user-friendly, and responsive solutions. From HTML to CSS and Javascript, I have developed websites from the ground up using current trends and frameworks.</p>
									</aside>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Container>
			</div>
			{/* <div className="home_details">
				<Container fixed>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<h1>Our Services</h1>
						</Grid>
						<Grid item md={4} sm={12} data-sal="flip-down" data-sal-duration="1500" className="card_ani">
							<Card>
								<CardContent>
									<aside>
										<Image filename="gatsby_logo.png" className="logos" />
										<h2>GatsbyJS</h2>
										<p>I used the GatsbyJS framework to help build this static website using easy templating and code optimizations but most importantly the access to use graphQL for data queries.</p>	
									</aside>
								</CardContent>
							</Card>
						</Grid>
						
						<Grid item md={4} sm={12} data-sal="flip-down" data-sal-duration="1500" className="card_ani">
							<Card>
								<CardContent>
									<aside>
										<Image filename="react_logo.png" className="logos" />
										<h2>ReactJS</h2>
										<p>Using React to build this static website helped build components that pieces everything together rather than using includes and different templates and layouts.</p>
									</aside>
								</CardContent>
							</Card>
						</Grid>

						<Grid item md={4} sm={12} data-sal="flip-down" data-sal-duration="1500" className="card_ani">
							<Card>
								<CardContent>
									<aside>
										<Image filename="material_ui_logo.png" className="logos" />
										<h2>Material-UI</h2>
										<p>I was able to utilize Material-UI for quick interface builds using pre-built components. This also helps with speed and consistent design for the user interface.</p>
										</aside>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Container>
			</div> */}

		</div>

)


export default Intro